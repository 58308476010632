import { environment } from './../environments/environment';
import { Component,AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, } from '@angular/router';

declare var $:any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements AfterViewInit {
  title = 'xamplify-content-preview';
  id:any;
  isLoading = true;
  baseUrl:string="";
  constructor(private router:Router,private headerTitle:Title){
    this.headerTitle.setTitle("Loading...");
    $("body").css("overflow", "hidden");
    console.log(environment.message);
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
   
  }


}
