import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-popup-loader',
  templateUrl: './modal-popup-loader.component.html',
  styleUrl: './modal-popup-loader.component.css'
})
export class ModalPopupLoaderComponent {

  updatedHeight = '935px';
}
