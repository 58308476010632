import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PreviewService {
  env = environment;
  constructor(private httpClient:HttpClient) { }

  getContentById(id:any,accessToken:any,isPartnerView:boolean,partnerId:any){
    let suffixUrl = isPartnerView ? 'findContentDetailsForPartnerView/'+id+'/'+partnerId:'findContentDetails/'+id;
    let apiUrl = this.env.apiUrl+"/dam/"+suffixUrl+"?access_token="+accessToken;
    return  this.httpClient.get(apiUrl);
  }
 
}
