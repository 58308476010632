import { environment } from './../../environments/environment';
import { PreviewService } from './../preview.service';
import { Component,OnInit, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Processor } from '../models/processor';
import { Title } from "@angular/platform-browser";

declare var $:any;
@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrl: './preview.component.css',
  providers:[Processor]
})

export class PreviewComponent implements OnInit {
  env = environment;
  isLoading = false;
  assetPath = "";
  assetType = "";
  imageFile = false;
  audioFile = false;
  videoFile = false;
  data:any;
  statusCode = 100;
  isDocViewerLoading = true;
  isExcludedFileTypes = false;
  isBeePdf = false;
  htmlBody = "";
  constructor(private route:ActivatedRoute,private router:Router,public previewService:PreviewService,
    public processor:Processor,public title:Title){

  }
 
  ngOnInit(): void {
    $("body").css("overflow", "hidden");
    this.processor.set(this.processor);
    let accessToken = atob(this.route.snapshot.params['accessToken']);
    let id = atob(this.route.snapshot.params['id']);
    let favIcon = atob(this.route.snapshot.params['favIcon']);
    let companyName = atob(this.route.snapshot.params['companyName']);
    this.title.setTitle(companyName);
    if(favIcon.includes("favicon.ico")){
      $("#favIcon").attr("href","xamplify.ico");
    }else{
      $("#favIcon").attr("href",favIcon);
    }
    let isPartnerView = this.router.url.indexOf("/p/preview/")>-1;
    this.previewAsset(id, accessToken,isPartnerView);
  }


  private previewAsset(id: string, accessToken: string,isPartnerView:boolean) {
    let partnerId:any;
    if(isPartnerView){
      partnerId = atob(this.route.snapshot.params['partnerId']);
    }else{
      partnerId = 0;
    }
    this.previewService.getContentById(id, accessToken,isPartnerView,partnerId).subscribe({
      next: (response: any) => {
        this.statusCode = response.statusCode;
        if (this.statusCode == 200) {
          let data = response.data;
          let map = response.map;
          this.isBeePdf = map!=undefined && map['isBeePdf'];
          if(this.isBeePdf){
            this.htmlBody= data;
            this.disableLoaderAndResetOverflowProperty();
          }else{
            this.assetPath = data.filePath;
            this.assetType = data.fileType;
            this.imageFile = data.imageFile;
            this.audioFile = this.assetType == "mp3";
            this.videoFile = this.assetType == "mp4";
            this.isExcludedFileTypes = this.imageFile || this.audioFile || this.videoFile;
            if (this.videoFile) {
              this.assetPath = environment.mediaPath + this.assetPath + "?access_token=" + accessToken;
            }
            if (this.isExcludedFileTypes) {
              this.disableLoaderAndResetOverflowProperty();
            }
          }
        } else if (this.statusCode == 404 || this.statusCode == 1000) {
          this.disableLoaderAndResetOverflowProperty();
        }
        this.processor.remove(this.processor);
      },
      error: (error: any) => {
        this.statusCode = JSON.parse(error["status"]);
        this.processor.remove(this.processor);
        this.disableLoaderAndResetOverflowProperty();
      }
    }

    );
  }

  private disableLoaderAndResetOverflowProperty() {
    this.isDocViewerLoading = false;
    $("body").css("overflow", "initial");
  }

  contentLoaded(){
    this.disableLoaderAndResetOverflowProperty();
    $(".overlay-popout-google").css({'width':'50px', 'background-color':'rgba(165, 165, 165)','right':'10px'});
  }




}

