import { Component,Input,OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-image-loader',
  templateUrl: './image-loader.component.html',
  styleUrl: './image-loader.component.css'
})
export class ImageLoaderComponent implements OnInit {
  @Input() image = "";
	@Input() loading = true;
  @Input() isStaticImage = false;
	className = "image-preview";
	loaderClassName ="center-image";
	constructor() {

	}
  ngOnInit(): void {
    $("body").addClass("animated-background");
    $("body").css("overflow", "hidden");
    this.loading = true;
    if(this.isStaticImage){
      this.className = "error-image";
    }
  }

	hideLoader() {
    setTimeout(() => {
     $("body").removeClass("animated-background");
     this.loading = false;
    }, 100);
		
	}
}
