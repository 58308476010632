
<!doctype html>
<html lang="en">
    <head>
        <!-- Required meta tags -->
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
        <title>xAmplify</title>
        <meta name="description" content="Union is a beautiful Bootstrap 4 template for multipurpose landing pages." /> 

        <!--Google fonts-->
        <link href="https://fonts.googleapis.com/css?family=Arimo:400,400i,700,700i" rel="stylesheet">

        <!--vendors styles-->
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
		<link rel="icon" href="https://i0.wp.com/xamplify.com/wp-content/uploads/2021/03/xamplify-Icon-80x80-1.png?fit=32%2C32&amp;ssl=1" sizes="32x32">

        <!-- Bootstrap CSS / Color Scheme -->
        <link rel="stylesheet" href="../../../assets/css/default.css" id="theme-color">
    </head>
    <body>

        <!--navigation-->
        <nav class="navbar navbar-expand-md navbar-light bg-white fixed-top sticky-navigation">
           <!-- <a class="navbar-brand mx-auto" href="index.html">
                Union
            </a>-->
			<img width="150" height="auto" src="https://i0.wp.com/xamplify.com/wp-content/uploads/2021/03/xAmplify.png?fit=191%2C70&amp;ssl=1" data-src="https://i0.wp.com/xamplify.com/wp-content/uploads/2021/03/xAmplify.png?fit=191%2C70&amp;ssl=1" class="attachment-full size-full wp-image-12 lazyloaded" alt="xAmplify">
            <button class="navbar-toggler navbar-toggler-right border-0" type="button" data-toggle="collapse" 
                    data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span data-feather="grid"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <a class="nav-link page-scroll" href="https://xamplify.com/about/">About</a>
                    </li>
                    
                    <li class="nav-item">
                        <a class="nav-link page-scroll" href="https://xamplify.com/blog/">Blog</a>
                    </li>
					<li class="nav-item">
                        <a class="nav-link page-scroll" href="https://xamplify.io/login">Login</a>
                </ul>
                <form class="form-inline">
                    <p class="mb-0 mx-3"><a class="page-scroll font-weight-bold" href="https://xamplify.com/#elementor-action%3Aaction%3Dpopup%3Aopen%26settings%3DeyJpZCI6IjY2NjkiLCJ0b2dnbGUiOmZhbHNlfQ%3D%3D">Get Pricing</a></p>
                </form>
            </div>
        </nav>

        <!--hero header-->
        <section class="pt-7 pt-md-8" id="home">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 mx-auto my-auto text-center">
					    <h3>A Partner Enablement Platform</h3>
                        <h1>Acquire and Retain Customers Through Partners</h1>
                        <p class="lead mt-4 mb-5">
                            Grow revenue with channel partners, ecosystems, resellers and more.
                        </p>
                        <p><img class="img-fluid" src="../../../assets/images/xa-mockup.png" alt="Mockup" /></p>
                    </div>
                </div>
            </div>
        </section>

        <!--footer / contact-->
        <footer class="py-6 bg-light">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 mx-auto text-center">
                        <ul class="list-inline">
                            <li class="list-inline-item"><a href="https://xamplify.com/privacy-policy-2/">Privacy</a></li>
                            <li class="list-inline-item"><a href="https://xamplify.com/terms-of-uses/">Terms</a></li>
                            <li class="list-inline-item"><a href="https://xamplify.com/subprocessors/">Sub Processors</a></li>
                            <li class="list-inline-item"><a href="https://xamplify.com/cookies-policy/">Cookies Policy</a></li>
                            <li class="list-inline-item"><a href="https://xamplify.com/blog/">Blog</a></li>
                        </ul>
                        <p class="text-muted small text-uppercase mt-4">
                            Follow us on social media
                        </p>
                        <ul class="list-inline social social-dark social-sm">
                            <li class="list-inline-item">
                                <a href="https://www.facebook.com/xAmplifyUSA"><i class="fa fa-facebook"></i></a>
                            </li>
                            <li class="list-inline-item">
                                <a href="https://twitter.com/xamplifys"><i class="fa fa-twitter"></i></a>
                            </li>
                            <li class="list-inline-item">
                                <a href="https://www.linkedin.com/company/xamplify/"><i class="fa fa-linkedin"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-12 text-muted text-center small-xl">
                        &copy;<script>document.write(new Date().getFullYear());</script> - All Rights Reserved
                    </div>
                </div>
            </div>
        </footer>

        <!--scroll to top-->
        <div class="scroll-top">
            <i class="fa fa-angle-up" aria-hidden="true"></i>
        </div>

     

        <!-- jQuery first, then Popper.js, then Bootstrap JS -->
        <script src="https://code.jquery.com/jquery-3.2.1.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js"></script>
        <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/feather-icons/4.5.0/feather.min.js"></script>
        <script src="../../../assets/scripts/scripts.js"></script>
    </body>
</html>