<app-modal-popup-loader *ngIf="isDocViewerLoading"></app-modal-popup-loader>

<ng-container *ngIf="!isBeePdf && !processor.isProcessing && statusCode==200">
  <div>
    <!--Ngx-Doc-Viewer-->
    <div *ngIf="!isExcludedFileTypes">
      <ngx-doc-viewer
      [url]="assetPath"
      viewerUrl="url"
      style="width:100%;height:90vh;"
      disableContent="none"
      (loaded)="contentLoaded()"
      (googleMaxChecks)="0"></ngx-doc-viewer>
    </div>
    <!---Image-->
    <div class="container" *ngIf="imageFile">
      <app-image-loader [loading]="imageFile" image="{{assetPath}}"></app-image-loader>
    </div>
    <!---Audio File-->
    <div class="container" *ngIf="audioFile">
      <audio controls>
        <source [src]="assetPath" type="audio/mpeg">
        Your browser does not support the audio tag.
      </audio>
    </div>
    <!---Video File-->
    <div class="container" *ngIf="videoFile">
      <video class="fit-preview-asset" controls>
        <source [src]="assetPath" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>
  </div> 
</ng-container>

<!--Bee PDF-->
<ng-container *ngIf="isBeePdf && !processor.isProcessing && statusCode==200">
  <div [innerHtml]="htmlBody | safeHtml"></div>
</ng-container>



<div *ngIf="statusCode==404">
  <app-image-loader [isStaticImage]="true"  image="../../assets/images/404.jpg"></app-image-loader>
</div>

<div *ngIf="statusCode==0">
  <div class="token-expired">Service Unavailable</div>
  <app-image-loader [isStaticImage]="true"  image="../../assets/images/site-not-available.jpg"></app-image-loader>
</div>

<div *ngIf="statusCode==500">
  <app-image-loader [isStaticImage]="true"  image="../../assets/images/500.png"></app-image-loader>
</div>

<div *ngIf="statusCode==401">
  <div class="token-expired">Sorry, your token has been expired</div>
  <app-image-loader [isStaticImage]="true"  image="../../assets/images/access-token-expired.jpg"></app-image-loader>
</div>

<div *ngIf="statusCode==1000">
  <div class="token-expired">Sorry,This file cannot be previewed</div>
  <app-image-loader [isStaticImage]="true"  image="../../assets/images/access-token-expired.jpg"></app-image-loader>
</div>

<div *ngIf="processor.isProcessing">
  <div class="processing-loader">
    <img src="../../assets/images/xamplify-icon.gif"  alt="No Image Found">
    <ng-template [ngTemplateOutlet]="textTemplate" [ngTemplateOutletContext]="{'text':'L'}"></ng-template>
    <ng-template [ngTemplateOutlet]="textTemplate" [ngTemplateOutletContext]="{'text':'O'}"></ng-template>
    <ng-template [ngTemplateOutlet]="textTemplate" [ngTemplateOutletContext]="{'text':'A'}"></ng-template>
    <ng-template [ngTemplateOutlet]="textTemplate" [ngTemplateOutletContext]="{'text':'D'}"></ng-template>
    <ng-template [ngTemplateOutlet]="textTemplate" [ngTemplateOutletContext]="{'text':'I'}"></ng-template>
    <ng-template [ngTemplateOutlet]="textTemplate" [ngTemplateOutletContext]="{'text':'N'}"></ng-template>
    <ng-template [ngTemplateOutlet]="textTemplate" [ngTemplateOutletContext]="{'text':'G'}"></ng-template>
    </div>
    

    <ng-template #textTemplate let-text="text">
      <p class="p-text">{{text}}</p>
    </ng-template>
  
</div>

