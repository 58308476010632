import { Directive } from '@angular/core';

@Directive({
  selector: '[appDynamicScript]'
})
export class DynamicScriptDirective {

  constructor() { }

}
