export class Processor {
    isProcessing:boolean = false;
    divHeight:string = "500px";
    className:string = "";



 public   set(processor:Processor){
    processor.isProcessing = true;
    return processor;
}
 
 public remove(processor:Processor){
     processor.isProcessing = false;
     return processor;
 }
}
