import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http'; // add this line
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { PreviewComponent } from './preview/preview.component';
import { WelcomeComponent } from './welcome/welcome/welcome.component';
import { DynamicScriptDirective } from './directives/dynamic-script.directive';
import { ModalPopupLoaderComponent } from './modal-popup-loader/modal-popup-loader.component';
import { ImageLoaderComponent } from './image-loader/image-loader.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';


@NgModule({
  declarations: [
    AppComponent,
    PreviewComponent,
    WelcomeComponent,
    DynamicScriptDirective,
    ModalPopupLoaderComponent,
    ImageLoaderComponent,
    SafeHtmlPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxDocViewerModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
